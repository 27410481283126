import { GatsbyBrowser } from 'gatsby'
import 'prismjs/plugins/command-line/prism-command-line.css'
import 'prismjs/plugins/line-numbers/prism-line-numbers.css'
import 'prismjs/themes/prism-tomorrow.css'
import React from 'react'
import ErrorBoundary from './src/components/ErrorBoundary/ErrorBoundary'
import './src/styles/custom.css'
import './src/styles/global.css'

export const onServiceWorkerUpdateReady: GatsbyBrowser['onServiceWorkerUpdateReady'] = () => {
  const answer = window.confirm(`This application has been updated. ` + `Reload to display the latest version?`)

  if (answer === true) {
    window.location.reload()
  }
}

export const onRouteUpdate: GatsbyBrowser['onRouteUpdate'] = ({ location, prevLocation }) => {}

export const wrapRootElement: GatsbyBrowser['wrapRootElement'] = ({ element }) => {
  return <ErrorBoundary>{element}</ErrorBoundary>
}
